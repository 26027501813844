<template>
  <div>
    <a-row type="flex" justify="center" style="margin-left: -10%; min-height: 650px;">
      <a-form style="width: 600px; margin: 0 auto" :form="form" @submit="submit">
        <a-form-item v-bind="formItemLayout" label=" ">
          <h1 style="text-align: center; padding-top: 70px">完善加盟商信息</h1>
        </a-form-item>
        <a-form-item
          v-bind="formItemLayout"
          :key="item.key"
          :label="item.label"
          v-for="item in [
            { key: 'company_name', label: '公司名称', note: '请填写公司名称' },
            { key: 'company_address', label: '公司注册地址', note: '请填写注册地址' },
            { key: 'company_code', label: '纳税人识别号', note: '请填写纳税人识别号' },
            { key: 'bank_account', label: '汇款账户名称', note: '用于打款支付的汇款户名' },
            { key: 'bank_number', label: '开户行及账号', note: '请填写对应的开户行及账号' },
            { key: 'email', label: '发票接收邮箱', note: '请填写电子发票接收邮箱' },
            //{ key: 'contact', label: '收货人及电话', note: '请填写收货人及电话' },
          ]"
        >
          <a-input v-decorator="[item.key, { rules }]" :placeholder="item.note" />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="收货信息">
          <a-input
            type="textarea"
            v-decorator="['shipping_info', { rules }]"
            placeholder="请填写您的收货地址及其它收货相关信息"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="酒店品牌">
          <a-radio-group name="radioGroup" v-decorator="['id_product_hotel', { rules }]">
            <a-radio
              v-for="item in hotel"
              :key="item.id_product_hotel"
              :value="item.id_product_hotel.toString()"
            >{{item.name}}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-bind="tailFormItemLayout">
          <!-- <a-checkbox v-model="isEnableSumit">
            我已同意舒悦商城
            <a href>用户政策</a>
          </a-checkbox>-->
          <a-alert type="warning" message="本次填写无需审批，再次修改将需要联系管理员审批。" banner />
        </a-form-item>
        <a-form-item v-bind="tailFormItemLayout">
          <div style="text-align: center">
            <a-button
              :loading="spinning"
              type="primary"
              size="large"
              style="width: 100%"
              html-type="submit"
            >提交</a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-row>
  </div>
</template>

<script>
const rules = [{ required: true, message: '必填字段' }]

export default {
  data() {
    return {
      rules,
      spinning: false,
      isEnableSumit: false,
      formItemLayout: {
        labelCol: { sm: { span: 6 } },
        wrapperCol: { sm: { span: 18 } }
      },
      tailFormItemLayout: {
        wrapperCol: { sm: { offset: 6 } }
      },
      hotel: {} // 酒店品牌
    }
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'register' })
  },
  mounted() {
    this.get('getHotel').then((res) => {
      this.hotel = res
      // console.log(this.hotel)
    })
  },
  methods: {
    submit(e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.spnning = true
          this.post('improveFranchiseeForFranchisee', values)
            .then((res) => {
              this.setSession('user', res)
              this.$router.replace({
                name: 'home',
                params: { showAnnouncement: true }
              })
              this.$message.success('完善信息成功')
            })
            .catch((err) => {
              this.$message.error(err.msg)
            })
        }
      })
    }
  }
}
</script>

<style scoped>
.ant-form-item {
  margin-bottom: 15px;
}
</style>
